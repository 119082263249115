import styled from "styled-components";
import { devices } from "@/constants/devices";
import { LinkNoPrefetch } from "../LinkNoPrefetch/LinkNoPrefetch";

export const PredictionCardLi = styled.li`
  list-style: none;
  width: 100%;
  padding: 16px;
  border-bottom: ${({ theme }) => `1px solid ${theme.darkerBorder}`};
  background-color: ${({ theme }) => theme.contentBackground};

  @media only screen and ${devices.laptop} {
    box-sizing: border-box;
    width: 50%;

    &.prediction:nth-child(2n + 1) {
      border-right: ${({ theme }) => `1px solid ${theme.darkerBorder}`};
    }

    &.prediction:nth-child(2n) {
      padding-left: 16px;
    }

    &.prediction:nth-last-child(-n + 2) {
      border-bottom: none;
      margin-bottom: 16px;
    }
  }
`;

export const PredictionMatchContainer = styled.div`
  display: flex;
`;

export const PredictionCompetition = styled.p`
  font-size: 0.625rem;
  color: ${({ theme }) => theme.secondary};
  font-weight: bold;
  margin: 0;
`;

export const PredictionTeamsContainer = styled.div`
  width: 60%;
  border-right: ${({ theme }) => `1px solid ${theme.darkerBorder}`};
`;

export const PredictionTeamContainer = styled.div`
  margin: 5px 0 10px;
  display: flex;
  align-items: center;

  .teamIconWrapper {
    max-width: 20px;
    max-height: 20px;

    img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const PredictionTeam = styled.p`
  font-size: 0.75rem;
  font-weight: bold;
  padding-left: 12px;
  margin: 0;
`;

export const PredictionDateDiv = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PredictionDateString = styled.p`
  color: ${({ theme }) => theme.secondary};
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1.3;
  margin: 2px 0;
  align-items: center;
`;

export const PredictionLink = styled(LinkNoPrefetch)`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.defaultText};

  :visited {
    color: ${({ theme }) => theme.defaultText};
  }
`;

export const StyledRightArrowContainer = styled.div`
  color: ${({ theme }) => theme.linkAngleRightColor};
  display: flex;
  align-self: center;
`;
