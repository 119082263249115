import Image from "next/image";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { ContentPayload } from "@/types/api/content";
import {
  PredictionCardLi,
  PredictionCompetition,
  PredictionDateDiv,
  PredictionDateString,
  PredictionLink,
  PredictionMatchContainer,
  PredictionTeam,
  PredictionTeamContainer,
  PredictionTeamsContainer,
  StyledRightArrowContainer,
} from "@/components/PredictionCard/PredictionCard.style";
import AngleRightIcon from "@/components/icons/AngleRightIcon";
import { addLeadingSlash, joinWordsWithHyphen } from "@/lib/string";

dayjs.extend(advancedFormat);

interface Props {
  prediction: ContentPayload;
}

export default function PredictionCard({
  prediction,
}: Props): JSX.Element | null {
  if (prediction.meta?.match) {
    // The link is almost certainly defined in full_url_path but we'll generate a fallback just in case
    const predictionLink = prediction.full_url_path
      ? addLeadingSlash(prediction.full_url_path)
      : `/predictions/${joinWordsWithHyphen(
          prediction.meta.match.competition_name
        )}/${prediction.slug}`;

    return (
      <PredictionCardLi className="prediction">
        <PredictionLink href={predictionLink} key={prediction._id}>
          <PredictionCompetition>
            {prediction.meta.match.competition_name}
          </PredictionCompetition>
          <PredictionMatchContainer>
            <PredictionTeamsContainer>
              <PredictionTeamContainer>
                <div className={"teamIconWrapper"}>
                  <Image
                    height={150}
                    width={150}
                    src={prediction.meta.match.team_a_badge}
                    alt={prediction.meta.match.team_a_name}
                  />
                </div>
                <PredictionTeam>
                  {prediction.meta.match.team_a_name}
                </PredictionTeam>
              </PredictionTeamContainer>
              <PredictionTeamContainer>
                <div className={"teamIconWrapper"}>
                  <Image
                    height={150}
                    width={150}
                    src={prediction.meta.match.team_b_badge}
                    alt={prediction.meta.match.team_b_name}
                  />
                </div>
                <PredictionTeam>
                  {prediction.meta.match.team_b_name}
                </PredictionTeam>
              </PredictionTeamContainer>
            </PredictionTeamsContainer>
            {prediction.meta.match.date_iso ? (
              <PredictionDateDiv>
                <PredictionDateString suppressHydrationWarning>
                  {dayjs(prediction.meta.match.date_iso).format("ddd Do MMM")}
                </PredictionDateString>
                <PredictionDateString suppressHydrationWarning>
                  {dayjs(prediction.meta.match?.date_iso).format("HH:mm")}
                </PredictionDateString>
              </PredictionDateDiv>
            ) : null}
            <StyledRightArrowContainer>
              <AngleRightIcon />
            </StyledRightArrowContainer>
          </PredictionMatchContainer>
        </PredictionLink>
      </PredictionCardLi>
    );
  }
  return null;
}
